<template>
  <div>
    <logo/>
    <br>
    <p>Now more secure than ever! Enter the master password to use publicnote.</p><br>
    <br>
    <p>Then enter any title above to access a note. No account required.</p><br>
    <br>
    <p>Every note is public. You are free to modify any note you find.</p><br>
    <br>
    <p>Every note is encrypted. You must know the title to decrypt the note.</p><br>
    <br>
    <p>Support publicnote:</p><br>
    <br>
    <p>Bitcoin: bc1q2vhvx4qjy4st6wduqu2atn2ur3yuvf6ttpdu04</p><br>
    <br>
    <p>Litecoin: LYMSJ313xJaUsAmucuYRkVJmGB8Ut9VDz8</p><br>
    <br>
    <p>Dogecoin: DATumCTp1QBG1Gpa3ko6bXPXccnFMFDgYC</p><br>
    <br>
    <p>Ethereum: 0x6abD6f3df07c06e4137269D7187661dE37441218</p><br>
    <br>
    <a class="link" href="https://github.com/routman/publicnote.com" target="_blank" rel="noopener">source code</a> <br>
    <br>
    <p>publicnote &copy; {{ new Date().getFullYear() }}</p><br>
  </div>
</template>

<script>
import logo from './logo.vue'
export default {
  name: 'home',
  components: {
    logo
  },
  data: function() {
    return {
      sot: this.$root.$data
    }
  }
}
</script>

<style scoped lang="scss">
@import "../assets/settings.scss";

p {
  display: inline;
}

</style>

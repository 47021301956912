import { render, staticRenderFns } from "./israel.vue?vue&type=template&id=dd8aabe4&scoped=true"
import script from "./israel.vue?vue&type=script&lang=js"
export * from "./israel.vue?vue&type=script&lang=js"
import style0 from "./israel.vue?vue&type=style&index=0&id=dd8aabe4&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd8aabe4",
  null
  
)

export default component.exports